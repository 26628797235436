<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="mb-2">
          <div class="font-weight-medium text-h6">
            {{ $trans("outlook_calendar_add_title") }}
          </div>
          {{ $trans("outlook_calendar_add_info") }}
        </div>
        <div class="d-flex align-start">
          <v-select
            v-model="employeeOutlookCalendar"
            :items="writeAccessCalendars"
            item-value="id"
            item-text="name"
            return-object
            class="mt-2"
            :label="$trans('select_calendar')"
            color="primary"
            :disabled="isSaving || isLoading"
            outlined
            hide-details="auto"
          />
        </div>
        <div v-if="isEnablingCalendar">
          <v-checkbox
            v-model="pushEventsCheckbox"
            :disabled="isSaving || isLoading"
            :label="$trans('push_all_future_events_to_calendar_checkbox')"
            hide-details
          />
        </div>
        <div class="mt-4 mb-2">
          <div class="font-weight-medium text-h6">
            {{ $trans("calendar_watch_title") }}
          </div>
          {{ $trans("outlook_calendar_watch_info") }}
        </div>
        <div class="d-flex align-start">
          <v-select
            v-model="employeeWatchOutlookCalendarIds"
            :items="readAccessCalendars"
            item-value="id"
            item-text="name"
            class="mt-2"
            :label="$trans('select_calendars')"
            color="primary"
            :disabled="isSaving || isLoading"
            outlined
            hide-details
            multiple
            small-chips
            deletable-chips
            clearable
          />
        </div>
        <div v-if="employeeWatchOutlookCalendarIds.length > 0">
          <v-checkbox
            v-model="isOutlookCalendarPublic"
            hide-details
            :disabled="isSaving || isLoading"
          >
            <template #label>
              <v-tooltip bottom max-width="400">
                <template #activator="{ on }">
                  <div v-on="on">
                    <span>{{ $trans("calendar_public_label") }}</span>
                  </div>
                </template>
                {{ $trans("calendar_public_tooltip") }}
              </v-tooltip>
            </template>
          </v-checkbox>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <v-row
      v-if="
        employeeOutlookCalendar &&
        employeeOutlookCalendar.allowed_online_meeting_providers &&
        employeeOutlookCalendar.allowed_online_meeting_providers.length > 0
      "
    >
      <v-col cols="12" class="text-h6 text-center">
        {{ $trans("microsoft_supported_tools") }}
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-center">
        <div>
          <span class="font-weight-medium">{{
            $trans("microsoft_outlook")
          }}</span>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-img
                max-width="40"
                :src="require(`@/assets/outlook-logo.png`)"
                v-on="on"
              ></v-img>
            </template>
            {{ $trans("microsoft_outlook") }}
          </v-tooltip>
        </div>
        <div
          v-for="tool in employeeOutlookCalendar.allowed_online_meeting_providers"
          :key="tool"
          class="mx-4"
        >
          <span class="font-weight-medium">{{ getTooltipForTool(tool) }}</span>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-img
                max-width="40"
                :src="getImageForTool(tool)"
                v-on="on"
              ></v-img>
            </template>
            {{ getTooltipForTool(tool) }}
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center">
        <v-btn
          v-if="!isEnablingCalendar"
          color="error"
          outlined
          :disabled="isLoading || isSaving"
          text
          @click="handleDisconnectFromOutlookCalendar"
        >
          <span v-text="$trans('disconnect_button_title')" />
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import api from "@/lib/calendesk-js-library/api/api";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "OutlookCalendar",
  mixins: [sharedActions],
  data() {
    return {
      employeeOutlookCalendar: null,
      employeeWatchOutlookCalendarIds: [],
      isSaving: false,
      isLoading: false,
      isOutlookCalendarPublic: false,
      code: null,
      state: null,
      pushEventsCheckbox: false,
    };
  },
  computed: {
    ...mapGetters({
      writeAccessCalendars: "microsoft/getWriteAccessCalendars",
      readAccessCalendars: "microsoft/getReadAccessCalendars",
      watchedOutlookCalendars: "microsoft/getWatchedCalendars",
    }),
    isEnablingCalendar() {
      return !!this.code && !!this.state;
    },
  },
  created() {
    if (
      this.getDialog.data &&
      this.getDialog.data.code &&
      this.getDialog.data.state
    ) {
      this.code = this.getDialog.data.code;
      this.state = this.getDialog.data.state;
      this.pushEventsCheckbox = true;

      this.enableCalendar();
    } else {
      this.fetchCalendarList();
    }
  },
  methods: {
    ...mapActions({
      updateOwnEmployee: "employee/updateOwn",
      loadAuthUser: "setup/loadAuthUser",
      fetchMicrosoftCalendars: "microsoft/fetchCalendars",
      fetchWatchedEmployeeOutlookCalendars:
        "microsoft/fetchWatchedEmployeeOutlookCalendars",
      updateWatchedEmployeeOutlookCalendars:
        "microsoft/updateWatchedEmployeeOutlookCalendars",
      setRecentlyDisabledByUser: "microsoft/setRecentlyDisabledByUser",
      enableOutlookCalendarForEmployee:
        "microsoft/enableOutlookCalendarForEmployee",
      getUserData: "auth/getUserData",
      pushEvents: "microsoft/pushEvents",
    }),
    enableCalendar() {
      this.isLoading = true;
      this.$emit("loading", this.isLoading);
      this.enableOutlookCalendarForEmployee({
        code: this.code,
        state: this.state,
      })
        .then(() => {
          this.fetchCalendarList();
        })
        .catch((error) => {
          errorNotification(null, error);
          this.isLoading = false;
          this.$emit("loading", this.isLoading);
          this.$emit("close");
        });
    },
    fetchCalendarList() {
      this.isLoading = true;
      this.$emit("loading", this.isLoading);

      Promise.all([
        this.fetchMicrosoftCalendars(),
        this.fetchWatchedEmployeeOutlookCalendars(),
        this.getUserData(),
      ])
        .then(() => {
          this.setEmployeeCalendar();
        })
        .catch(() => {
          this.setEmployeeCalendar();
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit("loading", this.isLoading);
        });
    },
    setEmployeeCalendar() {
      if (this.writeAccessCalendars && this.writeAccessCalendars.length > 0) {
        let primary = this.writeAccessCalendars.find(
          (calendar) =>
            calendar.id === this.loggedUser.employee.outlook_calendar_id
        );

        if (!primary) {
          primary = this.writeAccessCalendars.find(
            (calendar) => calendar.is_primary
          );
        }

        this.employeeOutlookCalendar = primary;
      }

      if (
        this.watchedOutlookCalendars &&
        this.watchedOutlookCalendars.length > 0
      ) {
        this.employeeWatchOutlookCalendarIds = this.watchedOutlookCalendars;
      }

      this.isOutlookCalendarPublic =
        !!this.loggedUser.employee.is_outlook_public;
    },
    async handleSave() {
      this.isSaving = true;
      this.$emit("ctaButtonLoading", true);

      try {
        await this.updateOwnEmployee({
          outlook_calendar_id: this.employeeOutlookCalendar.id,
          is_outlook_public: this.isOutlookCalendarPublic || false,
        });

        await this.updateWatchedEmployeeOutlookCalendars({
          calendar_ids: this.employeeWatchOutlookCalendarIds || [],
        });

        if (this.pushEventsCheckbox) {
          await this.pushEvents();
        }

        await this.loadAuthUser();

        this.setRecentlyDisabledByUser(false);
        successNotification("update_success");
        this.$emit("close");
      } catch (e) {
        this.$emit("ctaButtonLoading", false);
        errorNotification(null, e);
      }

      this.isSaving = false;
    },
    getImageForTool(name) {
      switch (name) {
        case "teamsForBusiness":
          return require(`@/assets/teams-logo.png`);
        case "skypeForConsumer":
        case "skypeForBusiness":
          return require(`@/assets/skype-logo.png`);
      }
    },
    getTooltipForTool(name) {
      switch (name) {
        case "teamsForBusiness":
          return this.$trans("microsoft_teams");
        case "skypeForConsumer":
        case "skypeForBusiness":
          return this.$trans("microsoft_skype");
      }
    },
    handleDisconnectFromOutlookCalendar() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("outlook_calendar_disconnect_dialog_title"),
        warning: this.$trans("outlook_calendar_disconnect_dialog_info"),
        confirmationText: this.$trans("disconnect_button_title"),
        confirmationButtonColor: "error",
        hideImportantInformation: true,
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          api
            .disableOutlookCalendarForEmployee()
            .then(() => {
              this.setRecentlyDisabledByUser(true);
              this.closeConfirmDialog();
              this.$emit("close");
              successNotification(
                "outlook_calendar_disconnect_success_message"
              );
            })
            .catch((error) => {
              errorNotification(null, error);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
              this.$emit("close");
            });
        },
      });
    },
  },
};
</script>
